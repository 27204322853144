let _ = !1;
const S = [],
  O = n => new Promise((t, e) => {
    if (typeof window > "u" || (window.storyblokRegisterEvent = r => {
      if (window.location === window.parent.location) {
        console.warn("You are not in Draft Mode or in the Visual Editor.");
        return;
      }
      _ ? r() : S.push(r);
    }, document.getElementById("storyblok-javascript-bridge"))) return;
    const s = document.createElement("script");
    s.async = !0, s.src = n, s.id = "storyblok-javascript-bridge", s.onerror = r => e(r), s.onload = r => {
      S.forEach(i => i()), _ = !0, t(r);
    }, document.getElementsByTagName("head")[0].appendChild(s);
  });
var I = Object.defineProperty,
  P = (n, t, e) => t in n ? I(n, t, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: e
  }) : n[t] = e,
  h = (n, t, e) => (P(n, typeof t != "symbol" ? t + "" : t, e), e);
function j(n) {
  return !(n !== n || n === 1 / 0 || n === -1 / 0);
}
function C(n, t, e) {
  if (!j(t)) throw new TypeError("Expected `limit` to be a finite number");
  if (!j(e)) throw new TypeError("Expected `interval` to be a finite number");
  const s = [];
  let r = [],
    i = 0;
  const o = function () {
      i++;
      const a = setTimeout(function () {
        i--, s.length > 0 && o(), r = r.filter(function (u) {
          return u !== a;
        });
      }, e);
      r.indexOf(a) < 0 && r.push(a);
      const l = s.shift();
      l.resolve(n.apply(l.self, l.args));
    },
    c = function (...a) {
      const l = this;
      return new Promise(function (u, p) {
        s.push({
          resolve: u,
          reject: p,
          args: a,
          self: l
        }), i < t && o();
      });
    };
  return c.abort = function () {
    r.forEach(clearTimeout), r = [], s.forEach(function (a) {
      a.reject(function () {
        Error.call(this, "Throttled function aborted"), this.name = "AbortError";
      });
    }), s.length = 0;
  }, c;
}
class b {
  constructor() {
    h(this, "isCDNUrl", (t = "") => t.indexOf("/cdn/") > -1), h(this, "getOptionsPage", (t, e = 25, s = 1) => ({
      ...t,
      per_page: e,
      page: s
    })), h(this, "delay", t => new Promise(e => setTimeout(e, t))), h(this, "arrayFrom", (t = 0, e) => [...Array(t)].map(e)), h(this, "range", (t = 0, e = t) => {
      const s = Math.abs(e - t) || 0,
        r = t < e ? 1 : -1;
      return this.arrayFrom(s, (i, o) => o * r + t);
    }), h(this, "asyncMap", async (t, e) => Promise.all(t.map(e))), h(this, "flatMap", (t = [], e) => t.map(e).reduce((s, r) => [...s, ...r], [])), h(this, "escapeHTML", function (t) {
      const e = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&#39;"
        },
        s = /[&<>"']/g,
        r = RegExp(s.source);
      return t && r.test(t) ? t.replace(s, i => e[i]) : t;
    });
  }
  /**
   * @method stringify
   * @param  {Object} params
   * @param  {String} prefix
   * @param  {Boolean} isArray
   * @return {String} Stringified object
   */
  stringify(t, e, s) {
    const r = [];
    for (const i in t) {
      if (!Object.prototype.hasOwnProperty.call(t, i)) continue;
      const o = t[i],
        c = s ? "" : encodeURIComponent(i);
      let a;
      typeof o == "object" ? a = this.stringify(o, e ? e + encodeURIComponent("[" + c + "]") : c, Array.isArray(o)) : a = (e ? e + encodeURIComponent("[" + c + "]") : c) + "=" + encodeURIComponent(o), r.push(a);
    }
    return r.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {String} regionCode region code, could be eu, us, cn, ap or ca
   * @return {String} The base URL of the region
   */
  getRegionURL(t) {
    const e = "api.storyblok.com",
      s = "api-us.storyblok.com",
      r = "app.storyblokchina.cn",
      i = "api-ap.storyblok.com",
      o = "api-ca.storyblok.com";
    switch (t) {
      case "us":
        return s;
      case "cn":
        return r;
      case "ap":
        return i;
      case "ca":
        return o;
      default:
        return e;
    }
  }
}
const A = function (n, t) {
    const e = {};
    for (const s in n) {
      const r = n[s];
      t.indexOf(s) > -1 && r !== null && (e[s] = r);
    }
    return e;
  },
  N = n => n === "email",
  L = () => ({
    singleTag: "hr"
  }),
  M = () => ({
    tag: "blockquote"
  }),
  z = () => ({
    tag: "ul"
  }),
  U = n => ({
    tag: ["pre", {
      tag: "code",
      attrs: n.attrs
    }]
  }),
  H = () => ({
    singleTag: "br"
  }),
  q = n => ({
    tag: `h${n.attrs.level}`
  }),
  B = n => ({
    singleTag: [{
      tag: "img",
      attrs: A(n.attrs, ["src", "alt", "title"])
    }]
  }),
  F = () => ({
    tag: "li"
  }),
  V = () => ({
    tag: "ol"
  }),
  J = () => ({
    tag: "p"
  }),
  D = n => ({
    tag: [{
      tag: "span",
      attrs: {
        "data-type": "emoji",
        "data-name": n.attrs.name,
        emoji: n.attrs.emoji
      }
    }]
  }),
  Y = () => ({
    tag: "b"
  }),
  K = () => ({
    tag: "s"
  }),
  W = () => ({
    tag: "u"
  }),
  G = () => ({
    tag: "strong"
  }),
  Q = () => ({
    tag: "code"
  }),
  X = () => ({
    tag: "i"
  }),
  Z = n => {
    if (!n.attrs) return {
      tag: ""
    };
    const t = new b().escapeHTML,
      e = {
        ...n.attrs
      },
      {
        linktype: s = "url"
      } = n.attrs;
    if (delete e.linktype, e.href && (e.href = t(n.attrs.href || "")), N(s) && (e.href = `mailto:${e.href}`), e.anchor && (e.href = `${e.href}#${e.anchor}`, delete e.anchor), e.custom) {
      for (const r in e.custom) e[r] = e.custom[r];
      delete e.custom;
    }
    return {
      tag: [{
        tag: "a",
        attrs: e
      }]
    };
  },
  tt = n => ({
    tag: [{
      tag: "span",
      attrs: n.attrs
    }]
  }),
  et = () => ({
    tag: "sub"
  }),
  st = () => ({
    tag: "sup"
  }),
  rt = n => ({
    tag: [{
      tag: "span",
      attrs: n.attrs
    }]
  }),
  it = n => {
    var t;
    return (t = n.attrs) != null && t.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `background-color:${n.attrs.color};`
        }
      }]
    } : {
      tag: ""
    };
  },
  nt = n => {
    var t;
    return (t = n.attrs) != null && t.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `color:${n.attrs.color}`
        }
      }]
    } : {
      tag: ""
    };
  },
  ot = {
    nodes: {
      horizontal_rule: L,
      blockquote: M,
      bullet_list: z,
      code_block: U,
      hard_break: H,
      heading: q,
      image: B,
      list_item: F,
      ordered_list: V,
      paragraph: J,
      emoji: D
    },
    marks: {
      bold: Y,
      strike: K,
      underline: W,
      strong: G,
      code: Q,
      italic: X,
      link: Z,
      styled: tt,
      subscript: et,
      superscript: st,
      anchor: rt,
      highlight: it,
      textStyle: nt
    }
  },
  at = function (n) {
    const t = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      },
      e = /[&<>"']/g,
      s = RegExp(e.source);
    return n && s.test(n) ? n.replace(e, r => t[r]) : n;
  };
class v {
  constructor(t) {
    h(this, "marks"), h(this, "nodes"), t || (t = ot), this.marks = t.marks || [], this.nodes = t.nodes || [];
  }
  addNode(t, e) {
    this.nodes[t] = e;
  }
  addMark(t, e) {
    this.marks[t] = e;
  }
  render(t, e = {
    optimizeImages: !1
  }) {
    if (t && t.content && Array.isArray(t.content)) {
      let s = "";
      return t.content.forEach(r => {
        s += this.renderNode(r);
      }), e.optimizeImages ? this.optimizeImages(s, e.optimizeImages) : s;
    }
    return console.warn(`The render method must receive an Object with a "content" field.
			The "content" field must be an array of nodes as the type ISbRichtext.
			ISbRichtext:
				content?: ISbRichtext[]
				marks?: ISbRichtext[]
				attrs?: any
				text?: string
				type: string
				
				Example:
				{
					content: [
						{
							content: [
								{
									text: 'Hello World',
									type: 'text'
								}
							],
							type: 'paragraph'
						}
					],
					type: 'doc'
				}`), "";
  }
  optimizeImages(t, e) {
    let s = 0,
      r = 0,
      i = "",
      o = "";
    typeof e != "boolean" && (typeof e.width == "number" && e.width > 0 && (i += `width="${e.width}" `, s = e.width), typeof e.height == "number" && e.height > 0 && (i += `height="${e.height}" `, r = e.height), (e.loading === "lazy" || e.loading === "eager") && (i += `loading="${e.loading}" `), typeof e.class == "string" && e.class.length > 0 && (i += `class="${e.class}" `), e.filters && (typeof e.filters.blur == "number" && e.filters.blur >= 0 && e.filters.blur <= 100 && (o += `:blur(${e.filters.blur})`), typeof e.filters.brightness == "number" && e.filters.brightness >= -100 && e.filters.brightness <= 100 && (o += `:brightness(${e.filters.brightness})`), e.filters.fill && (e.filters.fill.match(/[0-9A-Fa-f]{6}/g) || e.filters.fill === "transparent") && (o += `:fill(${e.filters.fill})`), e.filters.format && ["webp", "png", "jpeg"].includes(e.filters.format) && (o += `:format(${e.filters.format})`), typeof e.filters.grayscale == "boolean" && e.filters.grayscale && (o += ":grayscale()"), typeof e.filters.quality == "number" && e.filters.quality >= 0 && e.filters.quality <= 100 && (o += `:quality(${e.filters.quality})`), e.filters.rotate && [90, 180, 270].includes(e.filters.rotate) && (o += `:rotate(${e.filters.rotate})`), o.length > 0 && (o = "/filters" + o))), i.length > 0 && (t = t.replace(/<img/g, `<img ${i.trim()}`));
    const c = s > 0 || r > 0 || o.length > 0 ? `${s}x${r}${o}` : "";
    return t = t.replace(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g, `a.storyblok.com/f/$1/$2.$3/m/${c}`), typeof e != "boolean" && (e.sizes || e.srcset) && (t = t.replace(/<img.*?src=["|'](.*?)["|']/g, a => {
      var l, u;
      const p = a.match(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g);
      if (p && p.length > 0) {
        const g = {
          srcset: (l = e.srcset) == null ? void 0 : l.map(d => {
            if (typeof d == "number") return `//${p}/m/${d}x0${o} ${d}w`;
            if (typeof d == "object" && d.length === 2) {
              let k = 0,
                T = 0;
              return typeof d[0] == "number" && (k = d[0]), typeof d[1] == "number" && (T = d[1]), `//${p}/m/${k}x${T}${o} ${k}w`;
            }
          }).join(", "),
          sizes: (u = e.sizes) == null ? void 0 : u.map(d => d).join(", ")
        };
        let f = "";
        return g.srcset && (f += `srcset="${g.srcset}" `), g.sizes && (f += `sizes="${g.sizes}" `), a.replace(/<img/g, `<img ${f.trim()}`);
      }
      return a;
    })), t;
  }
  renderNode(t) {
    const e = [];
    t.marks && t.marks.forEach(r => {
      const i = this.getMatchingMark(r);
      i && i.tag !== "" && e.push(this.renderOpeningTag(i.tag));
    });
    const s = this.getMatchingNode(t);
    return s && s.tag && e.push(this.renderOpeningTag(s.tag)), t.content ? t.content.forEach(r => {
      e.push(this.renderNode(r));
    }) : t.text ? e.push(at(t.text)) : s && s.singleTag ? e.push(this.renderTag(s.singleTag, " /")) : s && s.html ? e.push(s.html) : t.type === "emoji" && e.push(this.renderEmoji(t)), s && s.tag && e.push(this.renderClosingTag(s.tag)), t.marks && t.marks.slice(0).reverse().forEach(r => {
      const i = this.getMatchingMark(r);
      i && i.tag !== "" && e.push(this.renderClosingTag(i.tag));
    }), e.join("");
  }
  renderTag(t, e) {
    return t.constructor === String ? `<${t}${e}>` : t.map(s => {
      if (s.constructor === String) return `<${s}${e}>`;
      {
        let r = `<${s.tag}`;
        if (s.attrs) for (const i in s.attrs) {
          const o = s.attrs[i];
          o !== null && (r += ` ${i}="${o}"`);
        }
        return `${r}${e}>`;
      }
    }).join("");
  }
  renderOpeningTag(t) {
    return this.renderTag(t, "");
  }
  renderClosingTag(t) {
    return t.constructor === String ? `</${t}>` : t.slice(0).reverse().map(e => e.constructor === String ? `</${e}>` : `</${e.tag}>`).join("");
  }
  getMatchingNode(t) {
    const e = this.nodes[t.type];
    if (typeof e == "function") return e(t);
  }
  getMatchingMark(t) {
    const e = this.marks[t.type];
    if (typeof e == "function") return e(t);
  }
  renderEmoji(t) {
    if (t.attrs.emoji) return t.attrs.emoji;
    const e = [{
      tag: "img",
      attrs: {
        src: t.attrs.fallbackImage,
        draggable: "false",
        loading: "lazy",
        align: "absmiddle"
      }
    }];
    return this.renderTag(e, " /");
  }
}
class lt {
  constructor(t) {
    h(this, "baseURL"), h(this, "timeout"), h(this, "headers"), h(this, "responseInterceptor"), h(this, "fetch"), h(this, "ejectInterceptor"), h(this, "url"), h(this, "parameters"), h(this, "fetchOptions"), this.baseURL = t.baseURL, this.headers = t.headers || new Headers(), this.timeout = t != null && t.timeout ? t.timeout * 1e3 : 0, this.responseInterceptor = t.responseInterceptor, this.fetch = (...e) => t.fetch ? t.fetch(...e) : fetch(...e), this.ejectInterceptor = !1, this.url = "", this.parameters = {}, this.fetchOptions = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("get");
  }
  post(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("post");
  }
  put(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("put");
  }
  delete(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("delete");
  }
  async _responseHandler(t) {
    const e = [],
      s = {
        data: {},
        headers: {},
        status: 0,
        statusText: ""
      };
    t.status !== 204 && (await t.json().then(r => {
      s.data = r;
    }));
    for (const r of t.headers.entries()) e[r[0]] = r[1];
    return s.headers = {
      ...e
    }, s.status = t.status, s.statusText = t.statusText, s;
  }
  async _methodHandler(t) {
    let e = `${this.baseURL}${this.url}`,
      s = null;
    if (t === "get") {
      const a = new b();
      e = `${this.baseURL}${this.url}?${a.stringify(this.parameters)}`;
    } else s = JSON.stringify(this.parameters);
    const r = new URL(e),
      i = new AbortController(),
      {
        signal: o
      } = i;
    let c;
    this.timeout && (c = setTimeout(() => i.abort(), this.timeout));
    try {
      const a = await this.fetch(`${r}`, {
        method: t,
        headers: this.headers,
        body: s,
        signal: o,
        ...this.fetchOptions
      });
      this.timeout && clearTimeout(c);
      const l = await this._responseHandler(a);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(l)) : this._statusHandler(l);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  setFetchOptions(t = {}) {
    Object.keys(t).length > 0 && "method" in t && delete t.method, this.fetchOptions = {
      ...t
    };
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(t) {
    const e = /20[0-6]/g;
    return new Promise((s, r) => {
      if (e.test(`${t.status}`)) return s(t);
      const i = {
        message: t.statusText,
        status: t.status,
        response: Array.isArray(t.data) ? t.data[0] : t.data.error || t.data.slug
      };
      r(i);
    });
  }
}
const x = "SB-Agent",
  w = {
    defaultAgentName: "SB-JS-CLIENT",
    defaultAgentVersion: "SB-Agent-Version",
    packageVersion: "6.0.0"
  };
let y = {};
const m = {};
class ct {
  /**
   *
   * @param config ISbConfig interface
   * @param endpoint string, optional
   */
  constructor(t, e) {
    h(this, "client"), h(this, "maxRetries"), h(this, "throttle"), h(this, "accessToken"), h(this, "cache"), h(this, "helpers"), h(this, "resolveCounter"), h(this, "relations"), h(this, "links"), h(this, "richTextResolver"), h(this, "resolveNestedRelations"), h(this, "stringifiedStoriesCache");
    let s = t.endpoint || e;
    if (!s) {
      const o = new b().getRegionURL,
        c = t.https === !1 ? "http" : "https";
      t.oauthToken ? s = `${c}://${o(t.region)}/v1` : s = `${c}://${o(t.region)}/v2`;
    }
    const r = new Headers();
    if (r.set("Content-Type", "application/json"), r.set("Accept", "application/json"), t.headers) for (const o in t.headers) r.set(o, t.headers[o]);
    r.has(x) || (r.set(x, w.defaultAgentName), r.set(w.defaultAgentVersion, w.packageVersion));
    let i = 5;
    t.oauthToken && (r.set("Authorization", t.oauthToken), i = 3), t.rateLimit && (i = t.rateLimit), t.richTextSchema ? this.richTextResolver = new v(t.richTextSchema) : this.richTextResolver = new v(), t.componentResolver && this.setComponentResolver(t.componentResolver), this.maxRetries = t.maxRetries || 5, this.throttle = C(this.throttledRequest, i, 1e3), this.accessToken = t.accessToken || "", this.relations = {}, this.links = {}, this.cache = t.cache || {
      clear: "manual"
    }, this.helpers = new b(), this.resolveCounter = 0, this.resolveNestedRelations = t.resolveNestedRelations || !0, this.stringifiedStoriesCache = {}, this.client = new lt({
      baseURL: s,
      timeout: t.timeout || 0,
      headers: r,
      responseInterceptor: t.responseInterceptor,
      fetch: t.fetch
    });
  }
  setComponentResolver(t) {
    this.richTextResolver.addNode("blok", e => {
      let s = "";
      return e.attrs.body && e.attrs.body.forEach(r => {
        s += t(r.component, r);
      }), {
        html: s
      };
    });
  }
  parseParams(t) {
    return t.token || (t.token = this.getToken()), t.cv || (t.cv = m[t.token]), Array.isArray(t.resolve_relations) && (t.resolve_relations = t.resolve_relations.join(",")), typeof t.resolve_relations < "u" && (t.resolve_level = 2), t;
  }
  factoryParamOptions(t, e) {
    return this.helpers.isCDNUrl(t) ? this.parseParams(e) : e;
  }
  makeRequest(t, e, s, r) {
    const i = this.factoryParamOptions(t, this.helpers.getOptionsPage(e, s, r));
    return this.cacheResponse(t, i);
  }
  get(t, e, s) {
    e || (e = {});
    const r = `/${t}`,
      i = this.factoryParamOptions(r, e);
    return this.client.setFetchOptions(s), this.cacheResponse(r, i);
  }
  async getAll(t, e, s, r) {
    const i = (e == null ? void 0 : e.per_page) || 25,
      o = `/${t}`,
      c = o.split("/"),
      a = s || c[c.length - 1],
      l = 1,
      u = await this.makeRequest(o, e, i, l),
      p = u.total ? Math.ceil(u.total / i) : 1;
    this.client.setFetchOptions(r);
    const g = await this.helpers.asyncMap(this.helpers.range(l, p), f => this.makeRequest(o, e, i, f + 1));
    return this.helpers.flatMap([u, ...g], f => Object.values(f.data[a]));
  }
  post(t, e, s) {
    const r = `/${t}`;
    return this.client.setFetchOptions(s), Promise.resolve(this.throttle("post", r, e));
  }
  put(t, e, s) {
    const r = `/${t}`;
    return this.client.setFetchOptions(s), Promise.resolve(this.throttle("put", r, e));
  }
  delete(t, e, s) {
    const r = `/${t}`;
    return this.client.setFetchOptions(s), Promise.resolve(this.throttle("delete", r, e));
  }
  getStories(t, e) {
    return this.client.setFetchOptions(e), this._addResolveLevel(t), this.get("cdn/stories", t);
  }
  getStory(t, e, s) {
    return this.client.setFetchOptions(s), this._addResolveLevel(e), this.get(`cdn/stories/${t}`, e);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _addResolveLevel(t) {
    typeof t.resolve_relations < "u" && (t.resolve_level = 2);
  }
  _cleanCopy(t) {
    return JSON.parse(JSON.stringify(t));
  }
  _insertLinks(t, e, s) {
    const r = t[e];
    r && r.fieldtype == "multilink" && r.linktype == "story" && typeof r.id == "string" && this.links[s][r.id] ? r.story = this._cleanCopy(this.links[s][r.id]) : r && r.linktype === "story" && typeof r.uuid == "string" && this.links[s][r.uuid] && (r.story = this._cleanCopy(this.links[s][r.uuid]));
  }
  /**
   *
   * @param resolveId A counter number as a string
   * @param uuid The uuid of the story
   * @returns string | object
   */
  getStoryReference(t, e) {
    return this.relations[t][e] ? (this.stringifiedStoriesCache[e] || (this.stringifiedStoriesCache[e] = JSON.stringify(this.relations[t][e])), JSON.parse(this.stringifiedStoriesCache[e])) : e;
  }
  _insertRelations(t, e, s, r) {
    s.indexOf(`${t.component}.${e}`) > -1 && (typeof t[e] == "string" ? t[e] = this.getStoryReference(r, t[e]) : Array.isArray(t[e]) && (t[e] = t[e].map(i => this.getStoryReference(r, i)).filter(Boolean)));
  }
  iterateTree(t, e, s) {
    const r = i => {
      if (i != null) {
        if (i.constructor === Array) for (let o = 0; o < i.length; o++) r(i[o]);else if (i.constructor === Object) {
          if (i._stopResolving) return;
          for (const o in i) (i.component && i._uid || i.type === "link") && (this._insertRelations(i, o, e, s), this._insertLinks(i, o, s)), r(i[o]);
        }
      }
    };
    r(t.content);
  }
  async resolveLinks(t, e, s) {
    let r = [];
    if (t.link_uuids) {
      const i = t.link_uuids.length,
        o = [],
        c = 50;
      for (let a = 0; a < i; a += c) {
        const l = Math.min(i, a + c);
        o.push(t.link_uuids.slice(a, l));
      }
      for (let a = 0; a < o.length; a++) (await this.getStories({
        per_page: c,
        language: e.language,
        version: e.version,
        by_uuids: o[a].join(",")
      })).data.stories.forEach(l => {
        r.push(l);
      });
    } else r = t.links;
    r.forEach(i => {
      this.links[s][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(t, e, s) {
    let r = [];
    if (t.rel_uuids) {
      const i = t.rel_uuids.length,
        o = [],
        c = 50;
      for (let a = 0; a < i; a += c) {
        const l = Math.min(i, a + c);
        o.push(t.rel_uuids.slice(a, l));
      }
      for (let a = 0; a < o.length; a++) (await this.getStories({
        per_page: c,
        language: e.language,
        version: e.version,
        by_uuids: o[a].join(","),
        excluding_fields: e.excluding_fields
      })).data.stories.forEach(l => {
        r.push(l);
      });
    } else r = t.rels;
    r && r.length > 0 && r.forEach(i => {
      this.relations[s][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  /**
   *
   * @param responseData
   * @param params
   * @param resolveId
   * @description Resolves the relations and links of the stories
   * @returns Promise<void>
   *
   */
  async resolveStories(t, e, s) {
    var r, i;
    let o = [];
    if (this.links[s] = {}, this.relations[s] = {}, typeof e.resolve_relations < "u" && e.resolve_relations.length > 0 && (typeof e.resolve_relations == "string" && (o = e.resolve_relations.split(",")), await this.resolveRelations(t, e, s)), e.resolve_links && ["1", "story", "url", "link"].indexOf(e.resolve_links) > -1 && ((r = t.links) != null && r.length || (i = t.link_uuids) != null && i.length) && (await this.resolveLinks(t, e, s)), this.resolveNestedRelations) for (const c in this.relations[s]) this.iterateTree(this.relations[s][c], o, s);
    t.story ? this.iterateTree(t.story, o, s) : t.stories.forEach(c => {
      this.iterateTree(c, o, s);
    }), this.stringifiedStoriesCache = {}, delete this.links[s], delete this.relations[s];
  }
  async cacheResponse(t, e, s) {
    (typeof s > "u" || !s) && (s = 0);
    const r = this.helpers.stringify({
        url: t,
        params: e
      }),
      i = this.cacheProvider();
    if (this.cache.clear === "auto" && e.version === "draft" && (await this.flushCache()), e.version === "published" && t != "/cdn/spaces/me") {
      const o = await i.get(r);
      if (o) return Promise.resolve(o);
    }
    return new Promise(async (o, c) => {
      var a;
      try {
        const l = await this.throttle("get", t, e);
        if (l.status !== 200) return c(l);
        let u = {
          data: l.data,
          headers: l.headers
        };
        if ((a = l.headers) != null && a["per-page"] && (u = Object.assign({}, u, {
          perPage: l.headers["per-page"] ? parseInt(l.headers["per-page"]) : 0,
          total: l.headers["per-page"] ? parseInt(l.headers.total) : 0
        })), u.data.story || u.data.stories) {
          const p = this.resolveCounter = ++this.resolveCounter % 1e3;
          await this.resolveStories(u.data, e, `${p}`);
        }
        return e.version === "published" && t != "/cdn/spaces/me" && (await i.set(r, u)), u.data.cv && e.token && (e.version === "draft" && m[e.token] != u.data.cv && (await this.flushCache()), m[e.token] = e.cv ? e.cv : u.data.cv), o(u);
      } catch (l) {
        if (l.response && l.status === 429 && (s = s ? s + 1 : 0, s < this.maxRetries)) return console.log(`Hit rate limit. Retrying in ${s} seconds.`), await this.helpers.delay(1e3 * s), this.cacheResponse(t, e, s).then(o).catch(c);
        c(l);
      }
    });
  }
  throttledRequest(t, e, s) {
    return this.client[t](e, s);
  }
  cacheVersions() {
    return m;
  }
  cacheVersion() {
    return m[this.accessToken];
  }
  setCacheVersion(t) {
    this.accessToken && (m[this.accessToken] = t);
  }
  clearCacheVersion() {
    this.accessToken && (m[this.accessToken] = 0);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(t) {
            return Promise.resolve(y[t]);
          },
          getAll() {
            return Promise.resolve(y);
          },
          set(t, e) {
            return y[t] = e, Promise.resolve(void 0);
          },
          flush() {
            return y = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom) return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve();
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this.clearCacheVersion(), this;
  }
}
const ut = (n = {}) => {
    const {
      apiOptions: t
    } = n;
    if (!t.accessToken) {
      console.error("You need to provide an access token to interact with Storyblok API. Read https://www.storyblok.com/docs/api/content-delivery#topics/authentication");
      return;
    }
    return {
      storyblokApi: new ct(t)
    };
  },
  pt = n => {
    if (typeof n != "object" || typeof n._editable > "u") return {};
    try {
      const t = JSON.parse(n._editable.replace(/^<!--#storyblok#/, "").replace(/-->$/, ""));
      return t ? {
        "data-blok-c": JSON.stringify(t),
        "data-blok-uid": t.id + "-" + t.uid
      } : {};
    } catch {
      return {};
    }
  };
let R,
  $ = "https://app.storyblok.com/f/storyblok-v2-latest.js";
const dt = (n, t, e = {}) => {
    var c;
    const r = !(typeof window > "u") && typeof window.storyblokRegisterEvent < "u",
      o = +new URL((c = window.location) == null ? void 0 : c.href).searchParams.get("_storyblok") === n;
    if (!(!r || !o)) {
      if (!n) {
        console.warn("Story ID is not defined. Please provide a valid ID.");
        return;
      }
      window.storyblokRegisterEvent(() => {
        new window.StoryblokBridge(e).on(["input", "published", "change"], l => {
          l.action === "input" && l.story.id === n ? t(l.story) : (l.action === "change" || l.action === "published") && l.storyId === n && window.location.reload();
        });
      });
    }
  },
  gt = (n = {}) => {
    var p, g;
    const {
      bridge: t,
      accessToken: e,
      use: s = [],
      apiOptions: r = {},
      richText: i = {},
      bridgeUrl: o
    } = n;
    r.accessToken = r.accessToken || e;
    const c = {
      bridge: t,
      apiOptions: r
    };
    let a = {};
    s.forEach(f => {
      a = {
        ...a,
        ...f(c)
      };
    }), o && ($ = o);
    const u = !(typeof window > "u") && ((g = (p = window.location) == null ? void 0 : p.search) == null ? void 0 : g.includes("_storyblok_tk"));
    return t !== !1 && u && O($), R = new v(i.schema), i.resolver && E(R, i.resolver), a;
  },
  E = (n, t) => {
    n.addNode("blok", e => {
      let s = "";
      return e.attrs.body.forEach(r => {
        s += t(r.component, r);
      }), {
        html: s
      };
    });
  },
  ht = n => !n || !(n != null && n.content.some(t => t.content || t.type === "blok" || t.type === "horizontal_rule")),
  ft = (n, t, e) => {
    let s = e || R;
    if (!s) {
      console.error("Please initialize the Storyblok SDK before calling the renderRichText function");
      return;
    }
    return ht(n) ? "" : (t && (s = new v(t.schema), t.resolver && E(s, t.resolver)), s.render(n));
  },
  mt = () => O($);
export { v as RichTextResolver, ot as RichTextSchema, ut as apiPlugin, ht as isRichTextEmpty, mt as loadStoryblokBridge, dt as registerStoryblokBridge, ft as renderRichText, pt as storyblokEditable, gt as storyblokInit, dt as useStoryblokBridge };